import React, { lazy, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { themeChange } from 'theme-change';
import checkAuth from './app/auth';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));

const App = () => {
  const [authStatus, setAuthStatus] = useState('PENDING');
  const [defaultLink, setDefaultLink] = useState('/app');
  const [showUpdate, setShowUpdate] = useState(false);

  useEffect(() => {
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    axios.defaults.baseURL = apiBaseURL;
    themeChange(false);

    const verifyAuth = async () => {
      const status = await checkAuth();
      if (status === "AUTH_REQUIRED") {
        setAuthStatus("AUTH_REQUIRED");
        setDefaultLink('/app/403');
      } else {
        setAuthStatus("AUTHORIZED");
        setDefaultLink(status.defaultLink);
      }
    };

    verifyAuth();

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then((registration) => {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  setShowUpdate(true);
                }
              }
            };
          };
        }).catch((error) => {
          console.error('Service worker registration failed:', error);
        });
      });
    }

  }, []);

  const handleUpdateNotification = () => {
    if (window.confirm('Nouvelle version disponible. Actualisez pour mettre à jour.')) {
      window.location.reload();
    } else {
      const refreshBanner = document.createElement('div');
      refreshBanner.innerHTML = `
        <div className="alert alert-info shadow-lg">
          <div className="flex items-center justify-center w-full space-x-2">
            <i className="fas fa-info-circle text-lg"></i>
            <span>Nouvelle version disponible. Cliquez ici pour actualiser.</span>
          </div>
        </div>
      `;
      refreshBanner.style.position = 'fixed';
      refreshBanner.style.bottom = '0';
      refreshBanner.style.width = '100%';
      refreshBanner.style.cursor = 'pointer';
      refreshBanner.style.zIndex = '1000';
      refreshBanner.style.display = 'flex';
      refreshBanner.style.justifyContent = 'center';
      refreshBanner.style.alignItems = 'center';

      refreshBanner.onclick = () => {
        window.location.reload();
      };

      document.body.appendChild(refreshBanner);
    }
  };

  useEffect(() => {
    if (authStatus === 'AUTHORIZED' && showUpdate) {
      handleUpdateNotification();
    }
  }, [authStatus, showUpdate]);

  if (authStatus === 'PENDING') {
    return (
      <div className="flex justify-center items-center h-screen">
        <progress className="progress w-56"></progress>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/app/*" element={<Layout />} />
        <Route path="*" element={<Navigate to={authStatus !== "AUTH_REQUIRED" ? defaultLink : "/login"} replace />} />
      </Routes>
    </Router>
  );
};

export default App;